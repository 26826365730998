import React from "react"

import styles from "./styles.module.css";

import BlockContent from '../blockContent'

const HelpTopicPage = ({title,sections,showSections}) => (
  <>
    <div className={styles.provacy_main_section}>
        <div className={[styles.top_banner_section,'d-flex','justify-content-center'].join(' ')}>
            <div className={['align-self-end', 'w-100'].join(' ')}>
                <div className="align-self-stretch">
                    <div className="container">
                        <div className={["row", "justify-content-md-center"].join(" ")}>
                            <div className={[styles.mpr_main_title ,'col-md-12', 'mb-5', 'pb-5'].join(' ')}>
                                <h2>{title}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.helpTopicContent}>
            <div className="container">
                <div className={["row", "justify-content-md-center",styles.topicContainer].join(" ")}>
                    {
                        showSections &&
                        <div className={["col-md-3",styles.sectionListColumn].join(' ')}>
                        <div className={["d-flex",'flex-column',styles.sectionListContainer,styles.stickySectionList,'align-items-stretch'].join(" ")}>
                        {
                            sections.map((section,index) => {
                                return (
                                    <React.Fragment key={index}>
                                    <a className={[styles.sectionListTitle].join(" ")} href={'#s'+(index+1)}>{section.title}</a>
                                    <div className={styles.sectionListLine}></div>
                                    </React.Fragment>
                                )
                            })
                        }
                        </div>
                    </div>
                    }
                    <div className={[showSections ? "col-md-9" : "col-md-12",styles.sectionContainer].join(' ')}>
                    {
                        sections.map((section,index) => {
                            return (
                                <React.Fragment key={index}>
                                {
                                    section.title &&
                                    <div className={[styles.sectionTitle,index != 0 && styles.nonFirstSectionTitle].join(" ")} id={'s'+(index+1)}>{section.title}</div>
                                }
                                {
                                    section._rawBody ? 
                                    <BlockContent blocks={section._rawBody} />
                                    :
                                    <></>
                                }
                                </React.Fragment>
                            )
                        })
                    }
                    </div>
                </div>
            </div>
        </div>
    </div>
  </>
)

export default HelpTopicPage
