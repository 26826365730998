import BaseBlockContent from '@sanity/block-content-to-react'
import React from 'react'
import Figure from './figure'
import Slideshow from './slideshow'

import typography from './typography.module.css'

const serializers = {
  types: {
    block (props) {
      switch (props.node.style) {
        case 'h1':
          return <h1 className={typography.responsiveTitle1}>{props.children}</h1>

        case 'h2':
          return <h2 className={typography.responsiveTitle2}>{props.children}</h2>

        case 'h3':
          return <h3 className={typography.responsiveTitle3}>{props.children}</h3>

        case 'h4':
          return <h4 className={typography.responsiveTitle4}>{props.children}</h4>

        case 'h5':
          return <h5 className={typography.responsiveTitle5}>{props.children}</h5>

        case 'h6':
          return <h6 className={typography.responsiveTitle6}>{props.children}</h6>
        case 'blockquote':
          return <blockquote className={typography.blockQuote}>{props.children}</blockquote>

        default:
          return <p className={typography.paragraph}>{props.children}</p>
      }
    },
    figure (props) {
      return <Figure {...props.node} />
    },
    slideshow (props) {
      return <Slideshow {...props.node} />
    }
  },
  marks: {
    blue: (props) => {
      return (
        <span style={{color: '#196ea4'}}>
          {props.children}
        </span>
      )
    },
    green: (props) => {
      return (
        <span style={{color: '#219c48'}}>
          {props.children}
        </span>
      )
    },
    // internalLink: ({mark, children}) => {
    //   const {slug = {}} = mark
    //   const href = `/${slug.current}`
    //   console.log(mark,slug,'internalLink');
    //   return <a href={href}>{children}</a>
    // },
    link: ({mark, children}) => {
      // Read https://css-tricks.com/use-target_blank/
      const { blank, href } = mark
      return blank ?
        <a href={href} target="_blank" rel="noopener">{children}</a>
        : <a href={href}>{children}</a>
    }
  },
  listItem: (props) => {
    return <li className={typography.paragraph}>{props.children}</li>
  }
}

const BlockContent = ({ blocks }) => <BaseBlockContent blocks={blocks} serializers={serializers} />

export default BlockContent
