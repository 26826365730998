import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/flayout"
import HelpTopicPage from "../components/helpTopicPage"

export class HelpTopic extends React.Component {

  render() {
    const topic = this.props.data.sanityHelpTopic;
    const sections = topic ? topic.sections: [];
    return (
      <Layout darkHeader={true} seoFields={{...seoFields,title:topic.title}} headerFixed={false} showAnnouncement={false}>
        <HelpTopicPage title={topic.title} sections={sections} showSections={topic.showSections}/>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
query sanityHelpTopicQuery($topicId: String!){
  sanityHelpTopic(id: { eq: $topicId}){
      title,
      slug,
      id,
      showSections,
      sections{
        title
        _rawBody
      }
    }
}
`

const seoFields = {
  description: "",
  // lang,
  // meta,
  // keywords,
  title: "Help Topic" 
}

export default HelpTopic;